import React from "react";
import logo from "../../gallery/logo.png";
import nochexLogo from "../../gallery/default/nochexLogo.jpeg";
import { Link } from "react-router-dom";
import { Facebook, Instagram } from "react-bootstrap-icons";

function Footer() {
  function goToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      <footer className="text-white footer-bottom">
        <div className="container">
          <div className="row">
            <section className="col-lg-3 col-md-4 col-12">
              <div className="row">
                <div className="col-12">
                  <Link onClick={goToTop} to="/">
                    <img src={logo} alt={"Sahi Search"} className="footer-brand" />
                  </Link>
                </div>
                <div className="col-12">
                  <Link onClick={goToTop} to="/">
                    <img style={{ paddingLeft: "20px", paddingRight: "20px" }} src={nochexLogo} alt={"Nochex Payment"} className="footer-brand" />
                  </Link>
                </div>
              </div>
            </section>
            <section className="col-lg-6 col-md-4 col-12 d-flex justify-content-center align-items-center">
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <Link onClick={goToTop} to="/about" className="footer-link px-2">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link onClick={goToTop} to="/privacy" className="footer-link px-2">
                    Privacy Policy
                  </Link>
                </li>
                <li className="nav-item">
                  <Link onClick={goToTop} to="/terms" className="footer-link px-2">
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </section>
            <section className="col-lg-3 col-md-4 text-end col-12 d-flex justify-content-center align-items-center flex-column">
              <p className="footer-copy-right">
                ©&nbsp;<span id="currentYear">2025</span> Sahi Search
              </p>
              <p className="footer-copy-right">A product of CNBLINK</p>
            </section>
          </div>
          <div className="row">
            <section className="col-lg-12 col-md-12 col-12 d-flex justify-content-center align-items-center">
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <a onClick={goToTop} href="https://www.instagram.com/sahisearch" target="_blank" rel="noopener noreferrer" className="footer-link px-2">
                    <Instagram />
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={goToTop} href="https://www.facebook.com/sahisearch" target="_blank" rel="noopener noreferrer" className="footer-link px-2">
                    <Facebook />
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
