import React from "react";
import { GeoAlt, Eye } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function BusinessCard(props) {
  function goToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  return (
    <>
      <div className="card business-card">
        <div className={props.RemoveBodyPaddingBottom ? "remove-body-padding-bottom card-body" : "card-body"}>
          <div className="d-flex flex-row gap-2">
            <div className="d-flex justify-content-center align-items-center" style={{ width: "7.5rem" }}>
              {props.image && (
                <Link
                  onClick={goToTop}
                  to={props.detail_url ? props.detail_url : "/"}
                  style={{
                    overflow: "hidden",
                    maxHeight: "5rem",
                  }}
                >
                  {props.page && props.page === "business" && <img className="img-fluid" src={props.image} alt="" style={{ maxHeight: "5rem" }} />}
                </Link>
              )}
            </div>

            {/* Second div takes the remaining space */}
            <div className="d-flex flex-column flex-grow-1">
              <h6
                className="card-title business-card-title"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                <Link onClick={goToTop} to={props.detail_url ? props.detail_url : "/"} className={"text-decoration-none"}>
                  {props.title ? props.title : ""}
                </Link>
              </h6>
              <p className="card-subtitle">
                <GeoAlt color="#0040c1" />
                &nbsp;
                {props.location ? props.location : ""}
                {props.postalcode ? ` - ${props.postalcode}` : ""}
              </p>
              <p className="mb-2">
                {props.category_id && (
                  <Link to={`/search?category=${props.category_id}&location=1&page=1&limit=10`}>
                    <span className="badge rounded-pill card-business">{props.type ? props.type : ""}</span>
                  </Link>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="card-footer bg-transparent border-1">
          <p className="card-footer-content d-flex justify-content-between align-items-center" style={{ margin: "0px" }}>
            <small className="card-views">
              <Eye size={18} />
              &nbsp;&nbsp;{props.views}&nbsp;views
            </small>
            <Link onClick={goToTop} to={props.detail_url ? props.detail_url : "/"} className="btn btn-sm btn-md float-end text-center card-btn-view-detail">
              View details
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
export default BusinessCard;
