import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCart, setBooking } from "../../app/features/event/eventSlice";
import { setNochex } from "../../app/features/payment/paymentSlice";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { useHistory } from "react-router-dom";

function EventLoginCheckoutForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.event.cart);
  const currentEvent = useSelector((state) => state.event.currentEvent);
  const user = useSelector((state) => state.user.user);
  const items = useSelector((state) => state.event.items);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: user ? user.first_name : "",
    last_name: user ? user.last_name : "",
    email: user ? user.email : "",
    phone: user ? user.phone : "",
    address1: user ? user.address1 : "",
    address2: user ? user.address2 : "",
    location_id: user ? user.location : "",
    postal_code: user ? user.postal_code : "",
    card_first_name: "",
    card_last_name: "",
    card_number: "",
    card_expiry: "",
    card_cvv: "",
    street_address: "",
  });

  const resetDiscount = () => {
    const updateCart = {
      ...cart,
      grandTotal: cart.subTotal,
      discount: 0.0,
      discount_type: 0,
      coupon_id: null,
    };
    dispatch(setCart(updateCart));

    const couponContainer = document.getElementById("coupon_container");
    couponContainer.style.display = "none";
    const couponLink = document.getElementById("coupon_link");
    couponLink.style.display = "block";
  };
  const handleChange = (event) => {
    setFormErrors({ ...formErrors, [event.target.name]: "" });
    if (event.target.name === "card_number") {
      let input = event.target.value;
      input = input.replace(/\D/g, "");
      input = input.replace(/(\d{4})(?=\d)/g, "$1 ");
      setFormData({ ...formData, card_number: input });
    } else if (event.target.name === "card_cvv") {
      let input = event.target.value;
      input = input.replace(/\D/g, "");
      const isAmex = formData.card_number && (formData.card_number.startsWith("34") || formData.card_number.startsWith("37"));
      if (isAmex) {
        input = input.slice(0, 4);
      } else {
        input = input.slice(0, 3);
      }
      setFormData({ ...formData, card_cvv: input });
    } else if (event.target.name === "card_expiry") {
      let input = event.target.value;

      input = input.replace(/\D/g, "");

      if (input.length > 2) {
        input = `${input.slice(0, 2)}/${input.slice(2, 4)}`;
      }
      setFormData({ ...formData, card_expiry: input });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const validateForm = () => {

    let errors = {};
    if (!formData.street_address) errors.street_address = "Enter street address";
    if (!formData.location_id) errors.location_id = "Enter your city";
    if (!formData.postal_code) errors.postal_code = "Enter postal code";

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const bookTicket = async (event) => {
    event.preventDefault();
    const loginButton = event.target;
    const loader = document.getElementById("checkoutloader");

    if (validateForm()) {
      loginButton.classList.remove("btn-login");
      loginButton.classList.add("btn-disable-login");
      loader.style.display = "block";
      var filteredItems = items.filter(function (el) {
        return el != null;
      });
      var bookingData = {
        first_name: user ? user.first_name : "",
        last_name: user ? user.last_name : "",
        email: user ? user.email : "",
        phone: user ? user.phone : "",
        address1: user ? user.address1 : "",
        address2: user ? user.address2 : "",
        location_id: user ? user.location_id : "",
        postal_code: user ? user.postal_code : "",
        buyer_id: user.id,
        total: cart.grandTotal,
        event_id: currentEvent.id,
        user_id: currentEvent.user_id,
        coupon_id: cart.coupon_id ? cart.coupon_id : null,
        discount_amount: cart.discount ? cart.discount : null,
        status: cart.grandTotal === 0 ? 1 : 0,
        items: filteredItems,
      };
      await axios
        .request({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/api/sanctum/csrf-cookie`,
        })
        .then(async (sanRep) => {
          await axios
            .request({
              method: "GET",
              url: `${process.env.REACT_APP_BASE_URL}/api/users/${currentEvent.user_id}`,
              headers: {
                Authorization: process.env.REACT_APP_API_KEY,
              },
            })
            .then(async function (userResponse) {
              
              await axios
                .request({
                  method: "POST",
                  url: `${process.env.REACT_APP_BASE_URL}/api/bookings`,
                  data: bookingData,
                  headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                  },
                })
                .then(async function (response) {
                  dispatch(setBooking(response.data.data));
                  const bookingSlug = response.data.data.slug;
                  if (cart.grandTotal === 0) {
                    history.push(`/ticket/${bookingSlug}`);
                    window.location.reload();
                    return;
                  } else {
                    loader.style.display = "none";
                    const closeModalButton = document.getElementById("close_modal");
                    if (closeModalButton) {
                      closeModalButton.click();
                    }
                    dispatch(
                      setNochex({
                        merchant_id: "Sahi_Search",
                        description: currentEvent.name,
                        amount: bookingData.total,
                        order_id: response.data.data.id,
                        email_address: bookingData.email,
                        customer_phone_number: bookingData.phone,
                        billing_fullname: `${bookingData.first_name} ${bookingData.last_name}`,
                        billing_address: formData ? formData.street_address : bookingData.address1 ? bookingData.address1 : "",
                        billing_postcode: formData ? formData.postal_code : bookingData.postal_code ? bookingData.postal_code : "",
                        billing_city: formData ? formData.location_id : bookingData.location_id ? bookingData.location_id : "",
                        callback_url: "https://sahisearch.com/manage/public/nochex_callback",
                        success_url: `https://sahisearch.com/ticket/${bookingSlug}`,
                      })
                    );
                  }
                })
                .catch(function (error) {
                  console.error(error);
                });
            })
            .catch(function (error) {
              console.error(error);
            });
        });
    }
  };
  return (
    <div className="login-container">
      <div className="row">
        <div className="col-12">
          <h3 className="register-heading">Payment details</h3>
          <h3 className="remaining-time">Time remaining : {props.timeRemaining}</h3>
          <p className="logged-in-user">
            Logged in as <span>{user.name}</span>
          </p>
          <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
          <form className="g-3" autoComplete="off">
            
            <div className="row">
              <h3 className="mt-3 register-sub-heading">Billing details</h3>
              <p className="required">
                <span className="field-required">*</span>Required
              </p>
              
              <div className="col-12 mb-3">
                <label htmlFor="street_address" className="form-label input-label">
                  Street address
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.street_address ? "error-input" : ""}`} name="street_address" placeholder="Street address" onChange={handleChange} value={formData.street_address} />
                {formErrors.street_address && <span className="error-text">{formErrors.street_address}</span>}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label htmlFor="location_id" className="form-label input-label">
                  City<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.location_id ? "error-input" : ""}`} name="location_id" placeholder="Your city" onChange={handleChange} value={formData.location_id} />
                {formErrors.location_id && <span className="error-text">{formErrors.location_id}</span>}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label htmlFor="postal_code" className="form-label input-label">
                  Post code<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.postal_code ? "error-input" : ""}`} name="postal_code" placeholder="Your post code" onChange={handleChange} value={formData.postal_code} />
                {formErrors.postal_code && <span className="error-text">{formErrors.postal_code}</span>}
              </div>
            </div>
            
            <div className="row mt-4">
              <div className="col-12 text-center">
                <button type="button" className="btn btn-login" onClick={(event) => bookTicket(event)}>
                  Pay now
                </button>
                <div className="mt-3" id="checkoutloader" style={{ display: "none" }}>
                  <BeatLoader color="#C6153B" />
                  <h4 className="mt-3" style={{ color: "#C6153B" }}>
                    Loading Payment Gateway...
                  </h4>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 mt-3">
          <div className="event-align-middle">
            <h3 className="login-event-title">{currentEvent.name}</h3>
            <p className="ticket-waiting">
              {cart.productsCount}&nbsp;
              {cart.productsCount === 1 ? "ticket" : "tickets"}
            </p>
            <p className="inclusive-fee">All ticket prices are inclusive of booking fees</p>
            <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
            <h3 className="grand-total">
              {cart.discount > 0 && <span className="discount-applied-success">£{cart.subTotal.toFixed(2)}</span>}&nbsp; £{cart.grandTotal.toFixed(2)}
            </h3>
            <button className="btn btn-back mb-2" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={resetDiscount}>
              Go back to change tickets
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventLoginCheckoutForm;
