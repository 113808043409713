import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

import "./css/custom.css";
import "./css/events.css";
import "./css/font.css";
import Navbar from "./components/template/Navbar";
import Footer from "./components/template/Footer";
//import PopularCategories from "./components/PopularCategories";
//import PopularLocations from "./components/PopularLocations";
//import Divider from "./components/Divider";
import SearchListing from "./components/common/SearchListing";
import BodyContent from "./components/body/BodyContent";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DetailWindow from "./components/common/DetailWindow";
//import Loader from "./components/Loader";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";
import { HelmetProvider } from "react-helmet-async";
//import Counter from "./components/Counter";
import EventsIndex from "./components/events/EventsIndex";
import EventsSearch from "./components/events/EventsSearch";
import EventDetail from "./components/events/EventDetail";
import EventSuccess from "./components/events/EventSuccess";
import Eticket from "./components/pages/Eticket";

const App = () => {
  /* useEffect(() => {
    localStorage.setItem("user", "");
  }); */
  return (
    <HelmetProvider>
      <Router>
        <div className="App min-vh-100">
          {/* <div id='globalLoader' style={{display:"display"}}>
            <Loader/>
            </div> */}
          <Navbar />
          <Switch>
            <Route exact path="/">
              <BodyContent pageTitle={"Home"} metaTitle="Exquisite Nepalese House Services: Bringing the Essence of Nepal to Your UK Home" metaContent="Our Nepalese Mechanics and services  are not only experts in their field but also uphold the values of trust, integrity, and respect. Rest assured that your property is in safe hands, as we treat every project as if it were our own." />
            </Route>
            <Route exact path="/events">
              <EventsIndex pageTitle={"Events"} />
            </Route>
            {/* <Route exact path="/counter">
              <Counter pageTitle={"Counter"} />
            </Route> */}
            <Route exact path="/search">
              <SearchListing page="list" />
            </Route>
            <Route exact path="/business/:id">
              <DetailWindow pageTitle={"Business Detail"} />
            </Route>
            <Route exact path="/property/:id">
              <DetailWindow pageTitle={"Property Detail"} />
            </Route>
            <Route exact path="/job/:id">
              <DetailWindow pageTitle={"Job Detail"} />
            </Route>
            <Route exact path="/events/:id">
              <EventDetail pageTitle={"Event Detail"} />
            </Route>
            <Route exact path="/ticket/:id">
              <EventSuccess pageTitle={"Event Ticket"} />
            </Route>
            <Route exact path="/contact">
              <Contact pageTitle={"Contact Us"} />
            </Route>
            <Route exact path="/about">
              <About pageTitle={"About Us"} />
            </Route>
            <Route exact path="/privacy">
              <Privacy pageTitle={"Privacy Policy"} />
            </Route>
            <Route exact path="/terms">
              <Terms pageTitle={"Terms & Conditions"} />
            </Route>
            <Route exact path="/e-ticket">
              <Eticket pageTitle={"E-Ticket"} />
            </Route>
            <Route exact path="/events/search">
              <EventsSearch pageTitle={"Events Search"} />
            </Route>
          </Switch>

          {/* <PopularLocations/>
              <Divider/> */}
        </div>
        <Footer />
      </Router>
    </HelmetProvider>
  );
};

export default App;
