import React, { useEffect } from "react";

const NochexPayment = ({ nochexData }) => {
  useEffect(() => {
    if (!nochexData) return;

    // Remove any existing script before adding a new one
    const existingScript = document.getElementById("ncx-config");
    if (existingScript) {
      existingScript.remove();
    }

    // Create new script
    const script = document.createElement("script");
    script.id = "ncx-config";
    script.setAttribute("ncxField-test_transaction", "fale");
    script.setAttribute("ncxField-description", nochexData.description);
    script.setAttribute("ncxField-api_key", process.env.REACT_APP_NOCHEX_API_KEY);
    script.setAttribute("ncxField-merchant_id", nochexData.merchant_id);
    script.setAttribute("ncxField-amount", nochexData.amount);
    script.setAttribute("ncxField-fullname", nochexData.billing_fullname);
    script.setAttribute("ncxField-email", nochexData.email_address);
    script.setAttribute("ncxField-phone", nochexData.customer_phone_number);
    script.setAttribute("ncxField-address", nochexData.billing_address);
    script.setAttribute("ncxField-city", nochexData.billing_city);
    script.setAttribute("ncxField-postcode", nochexData.billing_postcode);
    script.setAttribute("ncxField-country", "United Kingdom");
    script.setAttribute("ncxField-order_id", nochexData.order_id);
    script.setAttribute("ncxField-success_url", nochexData.success_url);
    script.setAttribute("ncxField-callback_url", nochexData.callback_url);

    // Append script to form
    const nochexForm = document.getElementById("nochexForm");
    if (nochexForm) {
      nochexForm.appendChild(script);
    }

    // Handle close event
    const handleClose = () => {
      
      window.location.reload();
    };

    // Attach event listener to close button
    setTimeout(() => {
      const closeBtn = document.getElementById("ncx-exit-btn");
      if (closeBtn) {
        closeBtn.addEventListener("click", handleClose);
      }
      const modalCheckout = document.getElementById("ncx-checkout");
      if (modalCheckout) {
        modalCheckout.setAttribute("style", "margin-top: 100px !important;");
      }
    }, 1000); // Delay to ensure button is available in the DOM

    // Cleanup function to remove event listener
    return () => {
      const closeBtn = document.getElementById("ncx-exit-btn");
      if (closeBtn) {
        closeBtn.removeEventListener("click", handleClose);
      }
    };
  }, [nochexData]);

  return (
    <form id="nochexForm" className="ncx-form" name="nochexForm">
      {/* Nochex Widget Script will be dynamically inserted here */}
    </form>
  );
};

export default NochexPayment;
