const navExpand = [].slice.call(document.querySelectorAll(".nav-expand"));
const backLink = `<li className="nav-item">
	<a className="nav-link nav-back-link" href="javascript:;">
		Back
	</a>
</li>`;

navExpand.forEach((item) => {
  item.querySelector(".nav-expand-content").insertAdjacentHTML("afterbegin", backLink);
  item.querySelector(".nav-link").addEventListener("click", () => item.classList.add("active"));
  item.querySelector(".nav-back-link").addEventListener("click", () => item.classList.remove("active"));
});

// ---------------------------------------
// not-so-important stuff starts here

/* const ham = document.getElementById('ham')
ham.addEventListener('click', function() {
	document.body.classList.toggle('nav-is-toggled')
}) */
