import React, { useEffect, useState } from "react";
import { DashLg, PlusLg } from "react-bootstrap-icons";
import Hourglass from "../../gallery/icons/hourglass.svg";
import { setTickets, incrementTicket, decrementTicket, setItem, setCart } from "../../app/features/event/eventSlice";
import { useDispatch, useSelector } from "react-redux";

function EventTicket(props) {
  const cart = useSelector((state) => state.event.cart);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const ticket = props.ticket;

  const dateFormat = (d) => {
    let date = new Date(d);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedDate = `${day} ${month}  ${year}, ${hours}:${minutes < 10 ? "0" + minutes : minutes} ${ampm}`;

    //const formattedDate = `${day} ${month}, ${year}`;

    return formattedDate;
  };
  const dateOnly = (d) => {
    let date = new Date(d);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;
    return formattedDate;
  };
  const updateTicket = {
    ...ticket,
    count: count,
  };
  const item = {
    event_id: ticket.event_id,
    ticket_id: ticket.id,
    quantity: 0,
    unit_price: ticket.price,
    discount: 0.0,
    subtotal: 0.0,
    user_id: 1,
  };

  useEffect(() => {
    dispatch(setTickets(updateTicket));
  }, [ticket.event_id]);

  const add = (ticket_id) => {
    if (ticket.quantity - ticket.sold >= count + 1) {
      setCount(count + 1);
      dispatch(incrementTicket(ticket.id));
      const ticketBookingFee = ticket.booking_fee ? ticket.booking_fee : 0;
      const updateItem = {
        ...item,
        quantity: count + 1,
        subtotal: (count + 1) * (ticket.price + ticketBookingFee),
      };
      dispatch(setItem(updateItem));
      const updateCart = {
        ...cart,

        productsCount: parseFloat(cart.productsCount) + 1,
        subTotal: parseFloat(cart.subTotal) + parseFloat(ticket.price) + parseFloat(ticketBookingFee),
        grandTotal: parseFloat(cart.subTotal) + parseFloat(ticket.price) + parseFloat(ticketBookingFee),

        discount: 0.0,
        discount_type: 0,
        coupon_id: null,
      };
      dispatch(setCart(updateCart));

      const couponContainer = document.getElementById("coupon_container");
      if (couponContainer) {
        couponContainer.style.display = "none";
        const couponSuccess = document.getElementById("coupon_success");
        couponSuccess.style.display = "none";
        const couponLink = document.getElementById("coupon_link");
        couponLink.style.display = "block";
      }
    }
  };
  const remove = (ticket_id) => {
    if (count > 0) {
      setCount(count - 1);
      dispatch(decrementTicket(ticket.id));
      const ticketBookingFee = ticket.booking_fee ? ticket.booking_fee : 0;
      const updateItem = {
        ...item,
        quantity: count - 1,
        subtotal: (count - 1) * (ticket.price + ticketBookingFee),
      };
      dispatch(setItem(updateItem));
      const updateCart = {
        ...cart,
        productsCount: parseFloat(cart.productsCount) - 1,
        subTotal: parseFloat(cart.subTotal) - (parseFloat(ticket.price) + parseFloat(ticketBookingFee)),
        grandTotal: parseFloat(cart.subTotal) - (parseFloat(ticket.price) + parseFloat(ticketBookingFee)),

        discount: 0.0,
        discount_type: 0,
        coupon_id: null,
      };
      dispatch(setCart(updateCart));

      const couponContainer = document.getElementById("coupon_container");
      if (couponContainer) {
        couponContainer.style.display = "none";
        const couponSuccess = document.getElementById("coupon_success");
        couponSuccess.style.display = "none";
        const couponLink = document.getElementById("coupon_link");
        couponLink.style.display = "block";
      }
    }
  };
  return (
    <div className={`${props.disable ? props.disable : "card ticket-card position-relative"} ${props.secret ? "secret-ticket-card" : ""}`}>
      <div className="card-body">
        {parseInt(ticket.quantity) - parseInt(ticket.sold) > 0 && props.disable === null && (
          <div className="position-absolute top-0 end-0">
            <div className="btn-group" role="group">
              <button type="button" className={count <= 0 ? "btn btn-ticket-cart disabled" : "btn btn-ticket-cart"} onClick={() => remove(ticket.id)}>
                <DashLg />
              </button>
              <button type="button" className="btn btn-ticket-cart" style={{ disabled: "disabled" }}>
                {count}
              </button>
              <button type="button" className={parseInt(ticket.quantity) - parseInt(ticket.sold) <= count || parseInt(ticket.purchase_limit) === count ? "btn btn-ticket-cart disabled" : "btn btn-ticket-cart"} onClick={() => add(ticket.id)}>
                <PlusLg />
              </button>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-8">
            <h5 className={`${props.disable ? "event-ticket-title opacity-50" : "event-ticket-title"}`}>{ticket.name.charAt(0).toUpperCase() + ticket.name.slice(1)}</h5>
          </div>
        </div>
        <div className="row">
         {new Date(ticket.start_date) > new Date() && props.disable && <p style={{opacity:'1'}} className="event-ticket-left">Ticket will be available from {dateOnly(ticket.start_date)}</p>}
          {parseInt(ticket.quantity) - parseInt(ticket.sold) < 20 && parseInt(ticket.quantity) - parseInt(ticket.sold) > 1 && <p className="event-ticket-left">Less Than {parseInt(ticket.quantity) - parseInt(ticket.sold)} left</p>}
          {parseInt(ticket.quantity) - parseInt(ticket.sold) === 1 && <p className="event-ticket-left">Only {parseInt(ticket.quantity) - parseInt(ticket.sold)} left</p>}
          {parseInt(ticket.quantity) - parseInt(ticket.sold) === 0 && <p className="event-ticket-left">Sold Out</p>}
          <p className={`${props.disable ? "event-ticket-price opacity-50" : "event-ticket-price"}`}>
            £{ticket.price}
            {ticket.booking_fee && <span className="trailing-booking-fee"> + £{ticket.booking_fee} booking fee </span>}
          </p>
          {parseInt(ticket.quantity) - parseInt(ticket.sold) > 0 && props.disable === null && ticket.detail && <p className="event-ticket-detail">{ticket.detail}</p>}
          {parseInt(ticket.quantity) - parseInt(ticket.sold) > 0 && props.disable === null && (
            <p className="event-ticket-text-fade">
              <img src={Hourglass} alt={""} size="3" /> Get before {dateFormat(ticket.expiry_date)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventTicket;
