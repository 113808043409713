import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, resetAskSignup } from "../../app/features/user/userSlice";
import { BeatLoader } from "react-spinners";

function EventRegisterCheckoutForm(props) {
  const dispacth = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    address1: "",
    address2: "",
    location_id: "",
    postal_code: "",
  });
  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const gotoLogin = () => dispacth(resetAskSignup());
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handlePassword = (event) => {
    const password = event.target.value;
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    if (password.length < 6) {
      let errors = {};
      errors.password = "Password must be at least 6 character";
      setFormErrors(errors);
    }
  };
  const handlePasswordConfirmation = (event) => {
    const confirmPassword = event.target.value;
    const inputPassword = document.getElementsByName("password");
    const password = inputPassword[0];
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    let errors = {};

    if (confirmPassword.length < 6) {
      errors.password_confirmation = "Password must be at least 6 character";
    } else if (password.value !== confirmPassword) {
      errors.password_confirmation = "Password not matched!";
    }
    setFormErrors(errors);
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const validateForm = () => {
    let errors = {};

    // General Fields
    if (!formData.first_name) errors.first_name = "Enter first name";
    if (!formData.last_name) errors.last_name = "Enter last name";
    if (!formData.email || !isValidEmail(formData.email)) errors.email = "Enter a valid email";
    if (!formData.confirm_email || !isValidEmail(formData.confirm_email)) errors.confirm_email = "Enter a valid confirmation email";
    if (formData.email && formData.confirm_email && formData.email !== formData.confirm_email) errors.confirm_email = "Email do not match";
    if (!formData.phone) errors.phone = "Enter your phone number";
    if (!formData.password) errors.password = "Enter password";
    if (!formData.password_confirmation) errors.password_confirmation = "Enter password to match";
    if (formData.password && formData.password_confirmation && formData.password !== formData.password_confirmation) errors.password_confirmation = "Password do not match";

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };
  const registerUser = async (event) => {
    event.preventDefault();
    const loginButton = event.target;
    const loader = document.getElementById("registerloader");
    goToTop();
    if (validateForm()) {
      loginButton.classList.remove("btn-register");
      loginButton.classList.add("btn-disable-register");
      loader.style.display = "block";
      await axios
        .request({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/api/sanctum/csrf-cookie`,
        })
        .then(async (sanRep) => {
          await axios
            .request({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/api/register`,
              data: formData,
            })
            .then(function (response) {
              dispacth(setUser(response.data.data));
              sessionStorage.setItem("user", JSON.stringify(response.data.data));
              localStorage.setItem("user", JSON.stringify(response.data.data));
              dispacth(resetAskSignup());
              loginButton.classList.remove("btn-disable-register");
              loginButton.classList.add("btn-register");
              loader.style.display = "block";
            })
            .catch(function (error) {
              loginButton.classList.remove("btn-disable-register");
              loginButton.classList.add("btn-register");
              loader.style.display = "none";
              console.error(error);
            });
          loginButton.classList.remove("btn-disable-register");
          loginButton.classList.add("btn-register");
          loader.style.display = "none";
        });
    }
  };
  const cart = useSelector((state) => state.event.cart);
  const currentEvent = useSelector((state) => state.event.currentEvent);

  return (
    <div className="login-container">
      <div className="row">
        <div className="col-12 mb-3">
          <h3 className="register-heading">Payment details</h3>
          <h3 className="remaining-time">Time remaining : {props.timeRemaining}</h3>
          <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
          <form className="g-3" autoComplete="off">
            <div className="row">
              <h3 className="register-sub-heading">Personal details</h3>
              <div className="col-md-6 col-12 mb-3">
                <label htmlFor="first_name" className="form-label input-label">
                  First name<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.first_name ? "error-input" : ""}`} name="first_name" placeholder="First name" onChange={handleChange} value={formData.first_name} required={"required"} />
                {formErrors.first_name && <span className="error-text">{formErrors.first_name}</span>}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label htmlFor="last_name" className="form-label input-label">
                  Last name<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.last_name ? "error-input" : ""}`} name="last_name" placeholder="Last name" onChange={handleChange} value={formData.last_name} required={"required"} />
                {formErrors.last_name && <span className="error-text">{formErrors.last_name}</span>}
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="staticEmail2" className="form-label input-label">
                  Email<span className="field-required">*</span>
                </label>
                <input type="email" className={`form-control input-text-size ${formErrors.email ? "error-input" : ""}`} name="email" placeholder="Enter email" onChange={handleChange} value={formData.email} required={"required"} />
                {formErrors.email && <span className="error-text">{formErrors.email}</span>}
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="confirm_email" className="form-label input-label">
                  Confirm Email<span className="field-required">*</span>
                </label>
                <input type="email" className={`form-control input-text-size ${formErrors.confirm_email ? "error-input" : ""}`} name="confirm_email" placeholder="Enter email address again" onChange={handleChange} value={formData.confirm_email} required={"required"} />
                {formErrors.confirm_email && <span className="error-text">{formErrors.confirm_email}</span>}
              </div>
              <div className="col-12 mb-3">
                <label className="form-label input-label">
                  Password<span className="field-required">*</span>
                </label>
                <input type="password" className={`form-control input-text-size ${formErrors.password ? "error-input" : ""}`} name="password" placeholder="Enter password" onChange={handlePassword} value={formData.password} required={"required"} />
                {formErrors.password && <span className="error-text">{formErrors.password}</span>}
              </div>
              <span className="password-limit">Password must be atleast 6 chracter</span>
              <div className="col-12 mb-3">
                <label className="form-label input-label">
                  Confirm Password<span className="field-required">*</span>
                </label>
                <input type="password" className={`form-control input-text-size ${formErrors.password_confirmation ? "error-input" : ""}`} name="password_confirmation" placeholder="Confirm password" onChange={handlePasswordConfirmation} value={formData.password_confirmation} required={"required"} />
                {formErrors.password_confirmation && <span className="error-text">{formErrors.password_confirmation}</span>}
              </div>

              <div className="col-12 mb-3">
                <label htmlFor="phone" className="form-label input-label">
                  Phone number<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.phone ? "error-input" : ""}`} name="phone" placeholder="Enter phone number" onChange={handleChange} value={formData.phone} required={"required"} />
                {formErrors.phone && <span className="error-text">{formErrors.phone}</span>}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn btn-register mb-3"
                  onClick={registerUser}
                  //data-bs-dismiss="modal"
                >
                  Continue to checkout
                </button>
                <div id="registerloader" style={{ display: "none" }}>
                  <BeatLoader color="#C6153B" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 mt-3">
          <div className="event-align-middle">
            <h3 className="login-event-title">{currentEvent.name}</h3>
            <p className="ticket-waiting">
              {cart.productsCount}&nbsp;
              {cart.productsCount === 1 ? "ticket" : "tickets"}
            </p>
            <p className="inclusive-fee">All ticket prices are inclusive of booking fees</p>
            <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
            <h3 className="grand-total">
              {cart.discount > 0 && <span className="discount-applied-success">£{cart.subTotal.toFixed(2)}</span>}&nbsp; £{cart.grandTotal.toFixed(2)}
            </h3>
            <button className="btn btn-back mb-2" onClick={gotoLogin}>
              Go back to login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventRegisterCheckoutForm;
