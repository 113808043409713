import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./features/counter/counterSlice";
import eventReducer from "./features/event/eventSlice";
import userReducer from "./features/user/userSlice";
import timerReducer from "./features/timer/timerSlice";
import navSlice from "./features/nav/navSlice";
import paymentSlice from "./features/payment/paymentSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    event: eventReducer,
    user: userReducer,
    timer: timerReducer,
    nav: navSlice,
    payment: paymentSlice,
  },
});
