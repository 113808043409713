import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nochexData: {
    merchant_id: "Sahi_Search",
    description: "",
    amount: 0.0,
    order_id: 0,
    email_address: "",
    customer_phone_number: "",
    billing_fullname: "",
    billing_address: "",
    billing_postcode: "",
    billing_city: "",
    callback_url: "https://sahisearch.com/manage/public/nochex_callback",
    success_url: "",
  },
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setNochex: (state, actions) => {
      state.nochexData = actions.payload;
    },
    resetNochex: (state) => {
      state.nochexData = {
        merchant_id: "Sahi_Search",
        description: "",
        amount: 0.0,
        order_id: 0,
        email_address: "",
        customer_phone_number: "",
        billing_fullname: "",
        billing_address: "",
        billing_postcode: "",
        billing_city: "",
        callback_url: "https://sahisearch.com/manage/public/nochex_callback",
        success_url: "",
      };
    },
  },
});

export const { setNochex, resetNochex } = paymentSlice.actions;

export default paymentSlice.reducer;
