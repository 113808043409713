import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetGuest } from "../../app/features/user/userSlice";
import { BeatLoader } from "react-spinners";
import { setBooking } from "../../app/features/event/eventSlice";
import { useHistory } from "react-router-dom";
import { setNochex } from "../../app/features/payment/paymentSlice";

function EventGuestCheckoutForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.event.cart);
  const currentEvent = useSelector((state) => state.event.currentEvent);
  const items = useSelector((state) => state.event.items);
  // const [selected, setSelected] = useState("card");
  const [formErrors, setFormErrors] = useState({});
  // const [nochexData, setNochexData] = useState(null);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    confirm_email: "",
    phone: "",
    address1: "",
    address2: "",
    location_id: "",
    postal_code: "",
    card_first_name: "",
    card_last_name: "",
    card_number: "",
    card_expiry: "",
    card_cvv: "",
    street_address: "",
  });

  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const gotoLogin = () => dispatch(resetGuest());
  const handleChange = (event) => {
    setFormErrors({ ...formErrors, [event.target.name]: "" });
    if (event.target.name === "card_number") {
      let input = event.target.value;

      input = input.replace(/\D/g, "");

      input = input.replace(/(\d{4})(?=\d)/g, "$1 ");

      setFormData({ ...formData, [event.target.name]: input });
    } else if (event.target.name === "card_cvv") {
      let input = event.target.value;

      // Remove any non-digit characters
      input = input.replace(/\D/g, "");
      const isAmex = formData.card_number.startsWith("34") || formData.card_number.startsWith("37");
      if (isAmex) {
        input = input.slice(0, 4);
      } else {
        input = input.slice(0, 3);
      }
      setFormData({ ...formData, [event.target.name]: input });
    } else if (event.target.name === "card_expiry") {
      let input = event.target.value;

      // Remove any non-digit characters
      input = input.replace(/\D/g, "");

      // Format the input as MM/YY
      if (input.length > 2) {
        input = `${input.slice(0, 2)}/${input.slice(2, 4)}`;
      }
      setFormData({ ...formData, [event.target.name]: input });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validateForm = () => {
    let errors = {};

    // General Fields
    if (!formData.first_name) errors.first_name = "Enter first name";
    if (!formData.last_name) errors.last_name = "Enter last name";
    if (!formData.email || !isValidEmail(formData.email)) errors.email = "Enter a valid email";
    if (!formData.confirm_email || !isValidEmail(formData.confirm_email)) errors.confirm_email = "Enter a valid confirmation email";
    if (formData.email !== formData.confirm_email) errors.confirm_email = "Emails do not match";
    if (!formData.phone) errors.phone = "Enter your phone number";

   
    if (!formData.street_address) errors.street_address = "Enter street address";
    if (!formData.location_id) errors.location_id = "Enter your city";
    if (!formData.postal_code) errors.postal_code = "Enter postal code";
    

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const registerUser = async (event) => {
    event.preventDefault();
    const loginButton = event.target;
    const loader = document.getElementById("checkoutloader");
    goToTop();
    if (validateForm()) {
      loginButton.classList.remove("btn-register");
      loginButton.classList.add("btn-disable-register");
      loader.style.display = "block";
      var filteredItems = items.filter(function (el) {
        return el != null;
      });

      await axios
        .request({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/api/sanctum/csrf-cookie`,
        })
        .then(async (sanRep) => {
          await axios
            .request({
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/api/register`,
              data: formData,
            })
            .then(async (registerResponse) => {
              var bookingData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                phone: formData.phone,
                address1: formData.street_address,
                address2: formData.address2 ? formData.address2 : null,
                location_id: formData.location_id,
                postal_code: formData.postal_code,
                buyer_id: registerResponse.data.data.id,
                total: cart.grandTotal,
                event_id: currentEvent.id,
                user_id: currentEvent.user_id,
                coupon_id: cart.coupon_id ? cart.coupon_id : null,
                discount_amount: cart.discount ? cart.discount : null,
                status: cart.grandTotal === 0 ? 1 : 0,
                items: filteredItems,
              };
              let merchantId = "Sahi_Search";
              await axios
                .request({
                  method: "GET",
                  url: `${process.env.REACT_APP_BASE_URL}/api/users/${currentEvent.user_id}`,
                  headers: {
                    Authorization: process.env.REACT_APP_API_KEY,
                  },
                })
                .then(async function (userResponse) {
                  // if (userResponse.data.data.marchant_id) {
                  //   merchantId = userResponse.data.data.marchant_id;
                  // }
                  await axios
                    .request({
                      method: "POST",
                      url: `${process.env.REACT_APP_BASE_URL}/api/bookings`,
                      data: bookingData,
                      headers: {
                        Authorization: process.env.REACT_APP_API_KEY,
                      },
                    })
                    .then(async function (response) {
                      dispatch(setBooking(response.data.data));
                      const bookingSlug = response.data.data.slug;
                      if (cart.grandTotal === 0) {
                        history.push(`/ticket/${bookingSlug}`);
                        window.location.reload();
                        return;
                      } else {
                        loader.style.display = "none";
                        const closeModalButton = document.getElementById("close_modal");
                        if (closeModalButton) {
                          closeModalButton.click();
                        }
                        dispatch(
                          setNochex({
                            merchant_id: merchantId,
                            description: currentEvent.name,
                            amount: bookingData.total,
                            order_id: response.data.data.id,
                            email_address: bookingData.email,
                            customer_phone_number: bookingData.phone,
                            billing_fullname: `${bookingData.first_name} ${bookingData.last_name}`,
                            billing_address: formData ? formData.street_address : bookingData.address1 ? bookingData.address1 : "",
                            billing_postcode: formData ? formData.postal_code : bookingData.postal_code ? bookingData.postal_code : "",
                            billing_city: formData ? formData.location_id : bookingData.location_id ? bookingData.location_id : "",
                            callback_url: "https://sahisearch.com/manage/public/nochex_callback",
                            success_url: `https://sahisearch.com/ticket/${bookingSlug}`,
                          })
                        );
                      }
                    })
                    .catch(function (error) {
                      console.error(error);
                    });
                })
                .catch(function (error) {
                  console.error(error);
                });
            })
            .catch(function (error) {
              setFormErrors(error.response.data.message);
              setTimeout(() => {
                setFormErrors({});
              }, 5000);
              loginButton.classList.remove("btn-disable-register");
              loginButton.classList.add("btn-register");
              loader.style.display = "none";
              console.error(error);
            });
        });
    }
  };

  return (
    <div className="login-container">
      <div className="row">
        <div className="col-12">
          <h3 className="register-heading">Payment details</h3>
          <h3 className="remaining-time">Time remaining : {props.timeRemaining}</h3>
          <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
          <form className=" g-3" autoComplete="off">
            <div className="row">
              <h3 className="register-sub-heading">Personal details</h3>
              <p className="required">
                <span className="field-required">*</span>Required
              </p>
              <div className="col-md-6 col-12 mb-3">
                <label htmlFor="first_name" className="form-label input-label">
                  First name<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.first_name ? "error-input" : ""}`} name="first_name" placeholder="First name" onChange={handleChange} value={formData.first_name} required={"required"} />
                {formErrors.first_name && <span className="error-text">{formErrors.first_name}</span>}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label htmlFor="last_name" className="form-label input-label">
                  Last name<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.last_name ? "error-input" : ""}`} name="last_name" placeholder="Last name" onChange={handleChange} value={formData.last_name} required={"required"} />
                {formErrors.last_name && <span className="error-text">{formErrors.last_name}</span>}
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="email" className="form-label input-label">
                  Email<span className="field-required">*</span>
                </label>
                <input type="email" className={`form-control input-text-size ${formErrors.email ? "error-input" : ""}`} name="email" placeholder="Your email" onChange={handleChange} value={formData.email} required={"required"} />
                {formErrors.email && <span className="error-text">{formErrors.email}</span>}
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="confirm_email" className="form-label input-label">
                  Confirm Email<span className="field-required">*</span>
                </label>
                <input type="email" className={`form-control input-text-size ${formErrors.confirm_email ? "error-input" : ""}`} name="confirm_email" placeholder="Your email address again" onChange={handleChange} value={formData.confirm_email} required={"required"} />
                {formErrors.confirm_email && <span className="error-text">{formErrors.confirm_email}</span>}
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="phone" className="form-label input-label">
                  Phone number<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.phone ? "error-input" : ""}`} name="phone" placeholder="Your phone number" onChange={handleChange} value={formData.phone} required={"required"} />
                {formErrors.phone && <span className="error-text">{formErrors.phone}</span>}
              </div>
            </div>
            {/* <div className="row mt-3">
              <div className="col-12 d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">
                <button type="button" className={`btn btn-payment-option ${selected === "card" ? "active" : ""}`} onClick={() => setSelected("card")}>
                  <img src={PAY_CARD} alt={""} />
                  &nbsp; Pay by card
                </button>

                <button type="button" className={`btn btn-payment-option ${selected === "apple" ? "active" : ""}`} onClick={() => setSelected("apple")}>
                  <img src={PAY_APPLE} alt={""} />
                  &nbsp; Apple pay
                </button>
              </div>
            </div> */}
            {/* {selected === "card" && ( */}
            <div className="row">
              <h3 className="mt-3 register-sub-heading">Billing details</h3>
              <p className="required">
                <span className="field-required">*</span>Required
              </p>
              {/* <div className="col-md-6 col-12 mb-3">
                  <label htmlFor="card_first_name" className="form-label input-label">
                    First name<span className="field-required">*</span>
                  </label>
                  <input type="text" className={`form-control input-text-size ${formErrors.card_first_name ? "error-input" : ""}`} name="card_first_name" placeholder="First name on card" onChange={handleChange} value={formData.card_first_name} required={"required"} />
                  {formErrors.card_first_name && <span className="error-text">{formErrors.card_first_name}</span>}
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <label htmlFor="last_name" className="form-label input-label">
                    Last name<span className="field-required">*</span>
                  </label>
                  <input type="text" className={`form-control input-text-size ${formErrors.card_last_name ? "error-input" : ""}`} name="card_last_name" placeholder="Last name on card" onChange={handleChange} value={formData.card_last_name} required={"required"} />
                  {formErrors.card_last_name && <span className="error-text">{formErrors.card_last_name}</span>}
                </div>
                <div className="col-12 mb-3">
                  <label htmlFor="card_number" className="form-label input-label">
                    Card number<span className="field-required">*</span>
                  </label>
                  <input type="text" className={`form-control input-text-size ${formErrors.card_number ? "error-input" : ""}`} name="card_number" placeholder="Enter card number" onChange={handleChange} value={formData.card_number} required={"required"} maxLength="19" />
                  {formErrors.card_number && <span className="error-text">{formErrors.card_number}</span>}
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <label htmlFor="card_expiry" className="form-label input-label">
                    Date of expiry<span className="field-required">*</span>
                  </label>
                  <input type="text" className={`form-control input-text-size ${formErrors.card_expiry ? "error-input" : ""}`} name="card_expiry" placeholder="MM/YY" onChange={handleChange} value={formData.card_expiry} />
                  {formErrors.card_expiry && <span className="error-text">{formErrors.card_expiry}</span>}
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <label htmlFor="card_cvv" className="form-label input-label">
                    Security code<span className="field-required">*</span>
                  </label>
                  <input type="text" className={`form-control input-text-size ${formErrors.card_cvv ? "error-input" : ""}`} name="card_cvv" placeholder="CVV" onChange={handleChange} value={formData.card_cvv} required={"required"} />
                  {formErrors.card_cvv && <span className="error-text">{formErrors.card_cvv}</span>}
                </div> */}
              <div className="col-12 mb-3">
                <label htmlFor="street_address" className="form-label input-label">
                  Street address
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.street_address ? "error-input" : ""}`} name="street_address" placeholder="Street address" onChange={handleChange} value={formData.street_address} />
                {formErrors.street_address && <span className="error-text">{formErrors.street_address}</span>}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label htmlFor="location_id" className="form-label input-label">
                  City<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.location_id ? "error-input" : ""}`} name="location_id" placeholder="Your city" onChange={handleChange} value={formData.location_id} />
                {formErrors.location_id && <span className="error-text">{formErrors.location_id}</span>}
              </div>
              <div className="col-md-6 col-12 mb-3">
                <label htmlFor="postal_code" className="form-label input-label">
                  Post code<span className="field-required">*</span>
                </label>
                <input type="text" className={`form-control input-text-size ${formErrors.postal_code ? "error-input" : ""}`} name="postal_code" placeholder="Your post code" onChange={handleChange} value={formData.postal_code} required={"required"} />
                {formErrors.postal_code && <span className="error-text">{formErrors.postal_code}</span>}
              </div>
            </div>
            {/* )} */}
            <div className="row mt-4">
              <div className="col-12 text-center">
                <button type="button" className="btn btn-register mb-3" onClick={registerUser}>
                  Pay now
                </button>
                <div className="mt-3" id="checkoutloader" style={{ display: "none" }}>
                  <BeatLoader color="#C6153B" />
                  <h4 className="mt-3" style={{ color: "#C6153B" }}>
                    Loading Payment Gateway...
                  </h4>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 mt-3">
          <div className="event-align-middle">
            <h3 className="login-event-title">{currentEvent.name}</h3>
            <p className="ticket-waiting">
              {cart.productsCount}&nbsp;
              {cart.productsCount === 1 ? "ticket" : "tickets"}
            </p>
            <p className="inclusive-fee">All ticket prices are inclusive of booking fees</p>
            <p className="not-reserved">Tickets are not reserved until you have completed payment.</p>
            <h3 className="grand-total">
              {cart.discount > 0 && <span className="discount-applied-success">£{cart.subTotal.toFixed(2)}</span>}&nbsp; £{cart.grandTotal.toFixed(2)}
            </h3>
            <button className="btn btn-back mb-2" onClick={gotoLogin}>
              Go back to login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventGuestCheckoutForm;
