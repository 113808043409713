import axios from "axios";
import React, { useState } from "react";
import EventTicket from "./EventTicket";
import { BeatLoader } from "react-spinners";

const SecretTicket = ({ currentEvent }) => {
  const [secretCode, setSecretCode] = useState("");
  const [verifiedTicket, setVerifiedTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleApply = async () => {
    if (!secretCode.trim()) {
      setError("Please enter a secret code.");
      return;
    }

    setLoading(true);
    setError("");

    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/tickets/secret/${currentEvent.id}?code=${secretCode}`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then(function (response) {
        setVerifiedTicket(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  const today = new Date();
  const ticketExpiry = verifiedTicket ? new Date(verifiedTicket.expiry_date) : null;
  const ticketStartAt = verifiedTicket ? new Date(verifiedTicket.start_date) : null;
  let disable = null;

  if (verifiedTicket && (parseInt(verifiedTicket.quantity) - parseInt(verifiedTicket.sold) <= 0 || ticketExpiry.getTime() < today.getTime() || ticketStartAt.getTime() >= today.getTime())) {
    disable = "card ticket-card ticket-card-disable position-relative";
  }

  return (
    <div className="ticket-container">
      {currentEvent.secret_tickets === 1 && !verifiedTicket ? (
        <div className="secret-code-form">
          <label className="secret-label mb-3"> Have a secret code?</label>
          <input type="text" className={`form-control secret-input mb-3 ${error ? "error-input" : ""}`} placeholder="Enter secret code" value={secretCode} onChange={(e) => setSecretCode(e.target.value)} />
          {error && <p className="error-text">{error}</p>}
          <button className={`"${loading ? "disabled" : ""} btn btn-secret mb-3 "`} onClick={handleApply}>
            {loading ? (
              <>
                {"Applying"}
                <BeatLoader />
              </>
            ) : (
              "Apply"
            )}
          </button>
        </div>
      ) : (
        verifiedTicket && (
          <>
            <p className="success-text">Secret code applied!</p>
            <EventTicket ticket={verifiedTicket} startDate={currentEvent.start_date} disable={disable} secret={true} />
          </>
        )
      )}
    </div>
  );
};

export default SecretTicket;
