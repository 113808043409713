import React, { useEffect, useState } from "react";

import EventLoginForm from "./EventLoginForm";
import EventLoginCheckoutForm from "./EventLoginCheckoutForm";
import EventRegisterCheckoutForm from "./EventRegisterCheckoutForm";
import { useSelector } from "react-redux";
// import axios from "axios";
import { useHistory } from "react-router-dom";
import EventGuestCheckoutForm from "./EventGuestCheckoutForm";

const EventLoginModal = React.memo((props) => {
  const history = useHistory();
  // const [locations, setLocations] = useState();
  const [timeRemaining, setTimeRemaining] = useState();
  const user = useSelector((state) => state.user.user);
  const askSignup = useSelector((state) => state.user.askSignup);
  const userGuest = useSelector((state) => state.user.guest);
  // async function fetchLocations() {
  //   await axios
  //     .request({
  //       method: "GET",
  //       url: process.env.REACT_APP_BASE_URL + "/api/locations",
  //       headers: {
  //         Authorization: process.env.REACT_APP_API_KEY,
  //       },
  //       params: {
  //         page: 1,
  //         limit: 300,
  //       },
  //     })
  //     .then(function (response) {
  //       setLocations(response.data.data);
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // }

  function timer() {
    var countDownDate = new Date().getTime() + 1000 * 60 * 15;
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setTimeRemaining(minutes + " : " + seconds);

      if (distance <= 1) {
        clearInterval(x);
        var myModal = document.getElementById("exampleModalToggle");
        if (myModal) {
          myModal.classList.remove("show");
          myModal.classList.add("hide");
          var modals = document.getElementsByClassName("modal-backdrop");
          var elementsArray = Array.from(modals);
          // Loop through the array and remove each element
          elementsArray.forEach(function (element) {
            element.parentNode.removeChild(element);
          });
          document.body.style.removeProperty("overflow");
          document.body.style.removeProperty("padding-right");
          history.push("/events");
        }
      }
    }, 1000);
  }
  useEffect(() => {
    timer();
    // fetchLocations();
  }, []);
  if (timeRemaining) {
    var detailPageTime = document.getElementById("detailPageTime");
    if (detailPageTime) {
      detailPageTime.textContent = "Time Remaining :" + timeRemaining;
    }
  }
  return (
    <div>
      <div className={`modal fade`} data-bs-backdrop="static" data-bs-keyboard="false" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content" style={{ overflowY: "inherit !important" }}>
            <div className="row">
              <div className="col-12 d-flex justify-content-end p-4">
                <button type="button" id="close_modal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
            </div>
            <div className="modal-body">
              {!user && !askSignup && !userGuest && <EventLoginForm timeRemaining={timeRemaining} />}
              {!user && !askSignup && userGuest && <EventGuestCheckoutForm timeRemaining={timeRemaining} />}
              {user && !askSignup && <EventLoginCheckoutForm timeRemaining={timeRemaining} />}
              {!user && askSignup && <EventRegisterCheckoutForm timeRemaining={timeRemaining} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default EventLoginModal;
